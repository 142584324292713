<template>
  <div class="expert-analysis">
    <h2 class="section-title">{{ title }}</h2>
    <p class="section-description">{{ description }}</p>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(chart, index) in charts" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <div class="chart-container" v-if="chart.data">
            <line-chart :data="chart.data" :options="chartOptions" />
          </div>
          <div class="chart-info">
            <span class="chart-title">{{ chart.title }}</span>
            <div class="chart-author">
              <el-avatar :size="small" :src="chart.authorAvatar"></el-avatar>
              <span>{{ chart.author }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { zhCN } from 'date-fns/locale';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import axios from "axios";
import 'chartjs-adapter-date-fns';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

export default {
  name: 'AdvancedStrategies',
  components: { LineChart },
  data() {
    return {
      title: '波动率策略',
      description: '利用期权等衍生工具，拥抱加密货币的高波动性',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              locale: zhCN,
              tooltipFormat: 'yyyy-MM-dd',
              displayFormats: {
                day: 'd'
              }
            },
            ticks: {
              callback: function(value, index, values) {
                // 获取日期
                const date = new Date(value);
                const day = date.getDate(); // 获取日期
                const month = date.getMonth(); // 获取月份
                if(index === 0){
                  return `${day}`;
                }
                const oldMonth = new Date(values[index - 1].value).getMonth(); // 确保直接访问的是日期值
                if (oldMonth !== month) {
                  // 如果是新月份，显示月份和日期
                  return `${month + 1}月`;
                } else {
                  // 否则只显示日期
                  return `${day}`;
                }
              }
            }
          }
        }
      },
      charts: [],  // 用于存储转换后的图表数据

    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/advanced_strategies'); // 替换为你的 API URL
        this.charts = this.convertToCharts(response.data.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToCharts(data) {
      const chartTemplates = [];

      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];
        const last14Dates = symbolData.dates.slice(-14).map(date => new Date(date));  // 取后 14 个日期
        const last14Amounts = symbolData.amount.slice(-14);  // 确保数据和日期一致
        let symbolStr = "美国大选做多波动率"; // 默认标题
        if (symbol === "collar") {
          symbolStr = "反比例价差"; // 特定 symbol 的标题
        }
        // 检查数据和日期的长度是否一致，确保数据完整
        // console.log(symbol)
        if (last14Dates.length !== last14Amounts.length) {
          console.warn(`Data length mismatch for symbol: ${symbolStr}`);
          return; // 数据不一致时，跳过当前 symbol 的处理
        }
        const chart = {
          title: `${symbolStr}净值`,
          author: 'ZKware.AI',
          authorAvatar: require('@/assets/logo.png'),
          data: {
            labels: last14Dates,
            datasets: [
              {
                label: 'USDT净值',
                data: last14Amounts,
                borderColor: '#0D83FE',
                fill: true,
                tension: 0.4
              }
            ]
          }
        };

        chartTemplates.push(chart);
      });
      return chartTemplates;
    }
  }

}

</script>

<style scoped>
.expert-analysis {
  margin-bottom: 20px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.chart-container {
  height: 200px;
}
.chart-info {
  padding: 14px;
}
.chart-title {
  font-weight: bold;
}
.chart-author {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.chart-author .el-avatar {
  margin-right: 8px;
}
</style>