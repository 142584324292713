<template>
  <div class="expert-analysis">
    <h2 class="section-title">{{ title }}</h2>
    <p class="section-description">{{ description }}</p>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(chart, index) in charts" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <div class="chart-container" v-if="chart.data">
            <line-chart :data="chart.data" :options="chartOptions" />
          </div>
          <div class="chart-info">
            <span class="chart-title">{{ chart.title }}</span>
            <div class="chart-author">
              <el-avatar :size="small" :src="chart.authorAvatar"></el-avatar>
              <span>{{ chart.author }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import axios from "axios";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  name: 'ExpertAnalysis',
  components: { LineChart },
  data() {
    return {
      title: '专家看盘',
      description: 'AI时刻监测价格走势和一系列指标，为您提供最新分析',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      charts: []  // 用于存储转换后的图表数据
    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/predict_price'); // 替换为你的 API URL
        this.charts = this.convertToCharts(response.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToCharts(data) {
      const chartTemplates = [];

      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];

        const chart = {
          title: `${symbol.replace('USDT', '')}价格预测`,
          author: 'ZKware.AI',
          authorAvatar: require('@/assets/logo.png'),
          data: {
            labels: symbolData.dates,
            datasets: [
              {
                label: 'Close',
                data: symbolData.close_price,
                borderColor: '#41B883',
                fill: false
              },
              {
                label: 'Predicted',
                data: symbolData.prediction,
                borderColor: '#0D83FE',
                fill: false
              }
            ]
          }
        };

        chartTemplates.push(chart);
      });
      return chartTemplates;
    }
  }

}

</script>

<style scoped>
.expert-analysis {
  margin-bottom: 20px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.chart-container {
  height: 200px;
}
.chart-info {
  padding: 14px;
}
.chart-title {
  font-weight: bold;
}
.chart-author {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.chart-author .el-avatar {
  margin-right: 8px;
}
</style>